import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import 'vue3-carousel/dist/carousel.css';
import "./assets/main.css";
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

const messages = {
  en: require('./locales/en.json'),
  tr: require('./locales/tr.json')
};

const i18n = createI18n({
  locale: 'tr', // Varsayılan dil
  fallbackLocale: 'en', // Yedek dil
  messages
});

library.add(fas,far,fab);

createApp(App)
  .use(router)
  .use(i18n)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');