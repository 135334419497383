<template>
  <div class="navbar" id="navbar">
    <nav :class="[{'onScroll': !view.topOfPage}, navbarClass]">
      <input class="menu-btn" type="checkbox" id="menu-btn" v-model="menuOpen"/>
      <label class="menu-icon" for="menu-btn">
        <span></span>
      </label>
      <div class="logo">
        <router-link :to="{name:'home'}" @click="menuOpen = false"><img class="cekirdek-logo" src="@/assets/cekirdek.png"></router-link>
      </div>
      <ul class="menu">
        <li class="extra-menu"><router-link :to="{name:''}" class="one">{{ $t('menu.institutional') }}<font-awesome-icon class="icon" :icon="['fas', 'chevron-right']"/></router-link>
          <ul>
            <li><router-link :to="{name:''}" @click="menuOpen = false">{{ $t('institutional.whoAreWe') }}</router-link></li>
            <li><router-link :to="{name:''}" @click="menuOpen = false">{{ $t('institutional.visionMission') }}</router-link></li>
          </ul>
        </li>
        <li class="extra-menu"><router-link :to="{name:''}" class="two" @click="menuOpen = true">{{ $t('menu.products') }}<font-awesome-icon class="icon" :icon="['fas', 'chevron-right']"/></router-link>
          <ul>
            <li><router-link :to="{name: ''}" @click="menuOpen = false">{{ $t('products.digitalTwinModelX') }}</router-link></li>
            <li><router-link :to="{name: ''}" @click="menuOpen = false">{{ $t('products.digitalTwinModelV') }}</router-link></li>
            <li><router-link :to="{name: ''}" @click="menuOpen = false">{{$t('products.frontalCollisionWarning')}}</router-link></li>
            <li><router-link :to="{name: ''}" @click="menuOpen = false">{{$t('products.sealIn')}}</router-link></li>
          </ul>
        </li>
        <li class="extra-menu"><router-link :to="{name:''}" class="four">{{ $t('menu.blog') }}<font-awesome-icon class="icon" :icon="['fas', 'chevron-right']"/></router-link>
          <ul>
            <li><router-link :to="{name: ''}" @click="menuOpen = false">Blog</router-link></li>
          </ul>
        </li>
        <li class="extra-menu"><router-link :to="{name:''}" class="five">{{ $t('menu.CONTACT') }}<font-awesome-icon class="icon" :icon="['fas', 'chevron-right']"/></router-link>
          <ul>
            <li><router-link :to="{name: 'iletisim'}" @click="menuOpen = false">{{ $t('menu.contact') }}</router-link></li>
          </ul>
        </li>
      </ul>
      <div class="navbar-icon">
        <a href="https://www.instagram.com/cekirdekarge/" target="_blank"><font-awesome-icon style="height: 15px; color: #ffff;" :icon="['fab', 'instagram']"/>
        </a>
        <a href="https://www.linkedin.com/company/cekirdekarge/" target="_blank"><font-awesome-icon style="height: 15px; color: #ffff" :icon="['fab', 'linkedin']"/>
        </a>
        <a href="https://twitter.com/cekirdekarge/" target="_blank"><font-awesome-icon style="height: 15px; color: #ffff" :icon="['fab', 'twitter']"/>
        </a>
        <a href="https://youtube.com/@cekirdekarge" target="_blank"><font-awesome-icon style="height: 15px; color: #ffff" :icon="['fab', 'youtube']"/>
        </a>
        <li style="color: #ffff; font-size: 14px; font-weight: bold;" @click="toggleLanguage">{{language}}</li>
      </div>
    </nav>   
  </div>
</template>

<script>
  export default {
    name: "Navbar",
    data() {
      return {
        view: {topOfPage: true},
        menuOpen: false,
        language: 'EN'
      }
    },
    beforeMount() {
      window.addEventListener('scroll', this.handleScroll)
    },
    computed: {
      navbarClass() {
        return this.$route.name === 'home' ? 'navbar navbar-transparent' : 'navbar navbar-colored';
      }
    },
    methods: {
      handleScroll(){
        if(window.pageYOffset>700){
          if(this.view.topOfPage) this.view.topOfPage = false
        }
        else {
          if(!this.view.topOfPage) this.view.topOfPage = true
        }
      },
      toggleLanguage(event) {
        event.preventDefault();
        if (this.$i18n.locale === 'en') {
          this.$i18n.locale = 'tr';
          this.language = 'EN';
        } else {
          this.$i18n.locale = 'en';
          this.language = 'TR';
        }
      }
    },
  };
</script>

<style>
.navbar-transparent {
  background-image: linear-gradient(to top,
  rgba(10,13,61, 0),
  rgba(10,13,61, 0.2), 
  rgba(10,13,61,0.4),
  rgba(10,13,61, 0.6), 
  rgba(10,13,61, 0.8),
  rgba(10,13,61,1)); 
}
.navbar-colored{
  background-color: rgb(10, 13 ,61);
}
.onScroll{
  background-color: rgb(10, 13 ,61);
}
nav{
  position: fixed;
  width: 100%;
  z-index: 1;
  justify-content: center;
}
nav ul{
  list-style: none;
  overflow: hidden;
}
.logo{
  padding-top: 30px;
  margin-right: 35px;
  padding-bottom: 13px;
  text-align: center;
  padding-right: 40px;
}
img{
  max-width: 120px;
  height: 40px;
}
.icon{
  margin-left: 10px;
}
nav li a{
  display: block;
  padding: 20px ;
  text-decoration: none;
  color: #ffff;
}
.menu .one{
  margin-top: 84px;
}
.menu li ul{
  display: none;
  font-size: 12px;
  padding-left: 20px;
  position: relative;
}
.extra-menu:hover ul{ 
  display: block;
}
nav .menu{
  clear: both;
  max-height: 0;
  font-weight: 600;
  font-size: 14px;
  background: rgb(10, 13, 61);
  position: relative;
  z-index: -1;
  top: -85px;
}
nav .menu-icon{
  cursor: pointer;
  display: inline-flex;
  float: left;
  padding: 50px 20px;
  position: relative;
  user-select: none;
}
nav .menu-icon span{
  background: #ffff;
  display: block;
  height:3px;
  position: relative;
  transition: all .2s ease-out;
  width: 30px;
}
nav .menu-icon span:before,
nav .menu-icon span:after{
  background: #ffff;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
nav .menu-icon span:before{
  top: 10px;
}
nav .menu-icon span:after{
  top: -10px;
}
nav .menu-btn {
  display: none;
}
nav .menu-btn:checked ~ .menu{
  max-height: 100%;
  top: -100px;
}
nav .menu-btn:checked ~ .menu-icon span{
  background: transparent;
}
nav .menu-btn:checked ~ .menu-icon span:before{
  transform: rotate(-45deg);
}
nav .menu-btn:checked ~ .menu-icon span:after{
  transform: rotate(45deg);
}
nav .menu-btn:checked ~ .menu-icon:not(.steps) span:before,
nav .menu-btn:checked ~ .menu-icon:not(.steps) span:after {
  top: 0;
}
.menu .one:hover,
.menu .two:hover,
.menu .three:hover,
.menu .four:hover,
.menu .five:hover,

.extra-menu ul li a:hover{ 
  text-shadow: 1px 1px 2px black,
                 0 0 25px white,
                 0 0 5px white;
}
.navbar-icon {
  position: fixed;
  cursor: pointer;
  justify-content: flex-end!important;
  margin-left: -80px;
  display: flex;
  width: 100%;
  top: 10px;
  right: 20px;
}
.navbar-icon a{
  padding-right: 8px;
}
.navbar-icon a:hover,
.navbar-icon li:hover{
  transform: scale(1.08); 
}

@media (min-width: 1024px){
  nav{
    position: fixed;
    width: 100%;
    z-index: 1;
  }
  nav li {
    float: left;
  }
  nav li a {
    padding: 18px 30px;
  }
  nav .menu {
    position: sticky;
    clear: none;
    max-height: none;
    float: right;
    background: none;
    padding-top: 30px;
    max-width: 100%;
    font-weight: 600;
    font-size: 14px;
  }
  nav .menu-icon {
    display: none;
  }
  .navbar-icon {
    right: 60px;
  }
  .navbar-icon a{
    padding-right: 8px;
  }
  .menu li ul {
    display: none;
    background: rgb(10, 13 ,61);
    position: fixed;
    padding: 0px;
    max-width: 260px;
    font-size: 14px;
  }
  .menu .one{
    margin-top: 0;
  }
  .logo{
    float: left;
    padding: 24px 30px;
    padding-bottom: 14px;
  }
  .cekirdek-logo {
    max-width: 120px;
    height: 40px;
  }
  .icon{
    display: none;
  }
  .one:hover,
  .two:hover,
  .three:hover,
  .four:hover,
  .five:hover{
    border-bottom: 2px solid #0087ca;
  }
}

</style>