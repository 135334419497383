import { createRouter, createWebHistory } from "vue-router";

const routes = [  {
    path: "/",
    name: "home",
    component: () => import("../components/Homepage.vue"),
  },
  {
    path: "/deneme",
    name: "deneme",
    component: () => import("../components/deneme.vue"),
  },
  {
    path: "/biz-kimiz",
    name: "bizKimiz",
    component: () => import("../components/BizKimiz.vue"),
  },
  {
    path: "/vizyon-misyon",
    name: "vizyonMisyon",
    component: () => import("../components/Vizyon.vue"),
  },
  {
    path: "/filo-sistem",
    name: "filoSistem",
    component: () => import("../components/FiloSistem.vue"),
  },
  {
    path: "/yapay-zeka",
    name: "yapayZeka",
    component: () => import("../components/YapayZeka.vue"),
  },
  {
    path: "/odeme-varlik-yonetimi",
    name: "varlikYonetimi",
    component: () => import("../components/VarlikYonetimi.vue"),
  },
  {
    path: "/yazilim-danismanligi",
    name: "yazilimDanismanligi",
    component: () => import("../components/YazilimDanismanligi.vue"),
  },
  {
    path: "/kiosk",
    name: "kiosk",
    component: () => import("../components/Kiosk.vue"),
  },
  {
    path: "/sensing",
    name: "sensing",
    component: () => import("../components/Sensing.vue"),
  },
  {
    path: "/iletisim",
    name: "iletisim",
    component: () => import("../components/Iletisim.vue"),
  },
  {
    path: "/kvkk",
    name: "kvkk",
    component: () => import("../components/Kvkk.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {top: 0}
  },
});

export default router;
