<template>
  <footer>
    <div class="footer-note">
      <div class="contents">
        <div class="logo">
          <img src="../assets/favicon.png" alt="">
        </div>
        <div class="footer-header">
          <h3>{{ $t('menu.institutional') }}</h3>
            <router-link :to="{name:''}">{{ $t('institutional.whoAreWe') }}</router-link>
            <router-link :to="{name:''}">{{ $t('institutional.visionMission') }}</router-link>
        </div>
        <div class="footer-header">
          <h3>{{ $t('menu.products') }}</h3>
            <router-link :to="{name:''}">{{ $t('products.digitalTwinModelX') }}</router-link>
            <router-link :to="{name:''}">{{ $t('products.digitalTwinModelV') }}</router-link>
            <router-link :to="{name:''}">{{ $t('products.frontalCollisionWarning') }}</router-link>
            <router-link :to="{name:''}">{{ $t('products.sealIn') }}</router-link>
        </div>
        <div class="footer-header">
          <h3>{{ $t('menu.blog') }}</h3>
            <router-link :to="{name:''}">Blog</router-link>
        </div>
        <div class="footer-header">
          <h3>{{ $t('menu.CONTACT') }}</h3>
          <router-link :to="{name:'iletisim'}">{{ $t('menu.contact') }}</router-link>
        </div>
      </div>
      <p class="footer-kvk">© 2020-2023 <a>Çekirdek AR-GE</a> | Tüm hakları saklıdır. | <router-link :to="{name:'kvkk'}" >KVKK</router-link></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  position: absolute;
  width: 100%;
  height: 350px;
  background: rgb(10, 13, 61);
}
.footer-note {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.footer-header {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  padding: 60px 60px;
  color: #ffff;
}
.footer-header h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}
.footer-header a {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #ffff;
}
.footer-header :hover {
  cursor: pointer;
  text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px white;
}
.contents {
  display: flex;
  margin: auto;
}
.footer-kvk {
  text-align: center;
  color:#fff; 
  opacity: 0.5; 
  font-size: 14px;
  padding-top: 20px;
}
.footer-kvk a{
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}
.logo img{
  height: auto;
  padding-top: 70px;
}
@media (max-width: 1250px) {
  .footer-header {
    padding: 60px 35px;
  }
}
@media (max-width: 920px) {
  .footer-header {
    padding: 60px 20px;
  }
}
@media (max-width: 770px) {
  .contents {
    display: grid;
    padding-right: -200px;
    margin: 0;
  }
  footer {
    height: 1030px;
  }
  .footer-header{
    float: left;
    padding: 40px 40px;
  }
  .footer-kvk {
    padding-top: 50px;
  }
  .logo img{
    height: auto;
    padding-top: 0px;
    margin-left: 80px;
  }
}
</style>
